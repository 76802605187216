export const formatMonth = (date: Date) => {
  return date.toLocaleString('en-GB', {
    month: 'short',
  })
}

export const capitalize = (value: string) => {
  return value.slice(0, 1).toUpperCase() + value.slice(1)
}

export const formatQuarter = (date: Date) => {
  const month = date.getMonth()
  if (month < 3) {
    return 'Q1'
  } else if (month < 6) {
    return 'Q2'
  } else if (month < 9) {
    return 'Q3'
  } else {
    return 'Q4'
  }
}
