import { Route, Switch } from 'wouter'

import { Dropbox } from './dropbox/Dropbox'
import { Contractors } from './contractors/Contractors'
import { Placements } from './placements/Placements'
import { PhaseManagement } from './phase-management/PhaseManagement'

export const IntranetTools = () => {
  return (
    <Switch>
      <Route path="/dropbox" component={Dropbox} />
      <Route path="/contractors" component={Contractors} />
      <Route path="/placements" component={Placements} />
      <Route path="/phase-management" component={PhaseManagement} />
    </Switch>
  )
}
