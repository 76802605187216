import { useCallback, useEffect, useState } from 'react'
import './PhaseManagement.css'
import { PlacementWithCandidate } from './types'
import { getApiUrl } from '../lib/phase_management_service'

const reverseDate = (date: string) => {
  return date.split('-').reverse().join('-')
}
const formatDate = (timestamp: number) => {
  return new Date(timestamp)
    .toISOString()
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')
}
type Phase = {
  EmployeeCode: string
  NameAV: string
  START: string
  EndDateProject: string
  NrWeeksWorkedBeforeJuly2019: string
  NrWeeksWorkedCOPRA: string
  NrMissingWeeks: string
  WEEKS: string
  PHASE: string
  UZB: string
  OFFER: string
  WHEN: string
  WHAT: string
  StippStartdate: string
  Plus: string
}

const mapPhaseToSelected = (phase: Phase): Phase => {
  return {
    ...phase,
    UZB: phase.UZB === 'y' ? '1' : '0',
    Plus: phase.Plus === 'y' ? '1' : '0',
    OFFER: phase.OFFER === 'y' ? '1' : '0',
  }
}

export const PhaseManagement = () => {
  const [phases, setPhases] = useState<Phase[]>()
  const [selected, setSelected] = useState<Phase | undefined>()

  const loadPhases = async () => {
    const result = await fetch(getApiUrl() + '/phase-management')
    const json = (await result.json()) as Phase[]
    setPhases(json)
  }

  useEffect(() => {
    loadPhases()
  }, [])

  const onClickRow = (phase: Phase) => {
    setSelected(mapPhaseToSelected(phase))
  }

  const onSave = async (phase: Phase) => {
    const result = await fetch(
      getApiUrl() + `/phase-management/${phase.EmployeeCode}`,
      {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(phase),
      }
    )
    const json = (await result.json()) as Phase[]
    setPhases(json)
    setSelected(undefined)
  }

  const widths = [80, 170, 100, 100, 80, 80, 80, 80, 100, 80, 80, 100, 450]
  console.log('selected:', selected)

  return (
    <>
      {selected && (
        <>
          <div className="modal-background"></div>
          <div className="modal-dialog">
            <div className="modal-title">Edit details</div>
            <div className="modal-content flex flex-col" style={{ gap: 5 }}>
              <div className="flex">
                <div style={{ width: 150 }}>Candidate code</div>
                <div>{selected.EmployeeCode}</div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>Name</div>
                <div>{selected.NameAV}</div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>PHASE</div>
                <div>
                  <input
                    style={{ width: 50 }}
                    type="number"
                    value={Number(selected.PHASE)}
                    onChange={(e) =>
                      setSelected({ ...selected, PHASE: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>AC</div>
                <div>
                  <input
                    type="checkbox"
                    checked={selected.UZB === '1'}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        UZB: e.target.checked ? '1' : '0',
                      })
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>STIPP</div>
                <div>
                  <input
                    type="date"
                    value={reverseDate(selected.StippStartdate)}
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        StippStartdate: reverseDate(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>PLUS</div>
                <div>
                  <input
                    type="checkbox"
                    checked={selected.Plus === '1'}
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        Plus: e.target.checked ? '1' : '0',
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>OFFER</div>
                <div>
                  <input
                    type="checkbox"
                    checked={selected.OFFER === '1'}
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        OFFER: e.target.checked ? '1' : '0',
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex">
                <div style={{ width: 150 }}>WHEN</div>
                <div>
                  <input
                    type="date"
                    value={reverseDate(selected.WHEN)}
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        WHEN: reverseDate(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex" style={{ gap: 12 }}>
                <div style={{ width: 150 }}></div>

                <button
                  className="modal-button-cancel"
                  onClick={() => setSelected(undefined)}
                >
                  Cancel
                </button>
                <button
                  className="modal-button"
                  onClick={() => onSave(selected)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="phase-management-container">
        <div className="phase-management-title">Phase Management Report</div>
        <div className="table">
          <div className="flex table-row pm-table-header">
            <div style={{ width: widths[0] }}>Code</div>
            <div style={{ width: widths[1] }}>Name</div>
            <div style={{ width: widths[2] }}>Start</div>
            <div style={{ width: widths[3] }}>End</div>
            <div style={{ width: widths[4] }}>Weeks</div>
            <div style={{ width: widths[5] }}>Missing</div>
            <div style={{ width: widths[6] }}>Phase</div>
            <div style={{ width: widths[7] }}>AC</div>
            <div style={{ width: widths[8] }}>Stipp</div>
            <div style={{ width: widths[9] }}>Plus</div>
            <div style={{ width: widths[10] }}>Offer</div>
            <div style={{ width: widths[11] }}>When</div>
            <div style={{ width: widths[12] }}>What</div>
          </div>
          {phases &&
            phases.map((phase) => (
              <div
                key={phase.EmployeeCode}
                className="flex pm-table-row"
                onClick={() => onClickRow({ ...phase })}
              >
                <div style={{ width: widths[0] }}>{phase.EmployeeCode}</div>
                <div style={{ width: widths[1] }}>{phase.NameAV}</div>
                <div style={{ width: widths[2] }}>{phase.START}</div>
                <div style={{ width: widths[3] }}>{phase.EndDateProject}</div>
                <div style={{ width: widths[4] }}>{phase.WEEKS}</div>
                <div style={{ width: widths[5] }}>{phase.NrMissingWeeks}</div>
                <div style={{ width: widths[6] }}>{phase.PHASE}</div>
                <div style={{ width: widths[7] }}>{phase.UZB}</div>
                <div style={{ width: widths[8] }}>{phase.StippStartdate}</div>
                <div style={{ width: widths[9] }}>{phase.Plus}</div>
                <div style={{ width: widths[10] }}>{phase.OFFER}</div>
                <div style={{ width: widths[11] }}>{phase.WHEN}</div>
                <div style={{ width: widths[12] }}>{phase.WHAT}</div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
