export const getApiUrl = () => {
  const isLocal = window.location.host.indexOf('localhost') !== -1
  return isLocal
    ? 'http://localhost:8002/api'
    : 'https://website.magno-it.nl/api'
}

const config = {
  baseUrl: getApiUrl(),
}

export const getSessionId = (): string => {
  const cookies = document.cookie.split(';')
  const magnoJwt = cookies.find(
    (cookie) => cookie.trim().indexOf('MagnoToken') === 0
  )
  if (magnoJwt) {
    const jwt = magnoJwt.split('=', 2)[1]
    return jwt
  }
  return localStorage.getItem('sessionId') || ''
}

export const authedGet = async (path: string) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'X-authenticated': getSessionId(),
      Cookie: document.cookie,
    },
  })
  return result
}

const authedMutate = async (method: string, path: string, body: FormData) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method,
    headers: {
      'X-authenticated': getSessionId(),
    },
    body: body,
  })
  return result
}

export const authedPost = async (path: string, body: FormData) => {
  return await authedMutate('POST', path, body)
}
