import React, { useState } from 'react'

import { authedPost } from '../lib/dropbox_service'
import { getMetadata } from '../lib/outlook-msg'

import './Dropbox.css'

type Job = {
  jobid: string
  email: string
}

const RESULT_MESSAGES: Record<string, string> = {
  CANDIDATE_NOT_FOUND: 'Candidate not found.',
  NO_JOB_ID: 'You must supply a job ID.',
  JOB_NOT_FOUND:
    'Cannot find a job with that ID. Please verify this is the correct 4-digit code in Bullhorn and the job is published to the website.',
  EXISTS_IN_QUEUE:
    'Candidate  CV has already been dropped for this job, and is currently queued for Shortlisting in Bullhorn.',
  OK: 'Candidate successfully submitted for job.',
}

export const Dropbox = () => {
  const [showModal, setShowModal] = useState(false)
  const [job, setJob] = useState<Job>({ jobid: '', email: '' })
  const [file, setFile] = useState<File>()
  const [result, setResult] = useState('')

  const submitApplication = async (
    jobId: string,
    email: string,
    file: File
  ) => {
    const formData = new FormData()
    formData.append('jobid', jobId)
    formData.append('email', email)
    formData.append('file', file)

    const result = await authedPost(`internal/application`, formData)
    const json = await result.json()
    setResult(json.result)
    setShowModal(false)
    setTimeout(() => {
      setResult('')
    }, 3000)
  }

  const onDragOver = (event: any) => {
    event.preventDefault()
  }

  const resubmitJob = async () => {
    if (file) {
      submitApplication(job.jobid, job.email, file)
    }
  }

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault()
    try {
      if (event.dataTransfer.files.length !== 1) {
        throw new Error(`Must drop exactly one file`)
      }
      const file = event.dataTransfer.files[0]
      if (file.name.indexOf('.') === -1) {
        throw new Error(
          `You must drag a file with a valid file extension into the dropbox: .eml, .doc(x), .pdf`
        )
      }
      setFile(file)
      const fileReader = new FileReader()
      fileReader.onload = () => {
        const fileData = fileReader.result
        const { jobId, email } = getMetadata(fileData as ArrayBuffer)
        if (!jobId || !email) {
          setJob({ jobid: jobId || '', email: email || '' })
          setShowModal(true)
          return
        }
        submitApplication(jobId, email, file)
      }
      fileReader.readAsArrayBuffer(file)
    } catch (e: any) {
      alert(e.message)
    }
  }

  return (
    <div className="container">
      {result && (
        <div className="modal-overlay">
          <div className="modal">
            <p>{RESULT_MESSAGES[result]}</p>
          </div>
        </div>
      )}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p>Enter the job details for this job</p>
            <label htmlFor="job_id">
              Bullhorn Job ID:
              <input
                id="job_id"
                placeholder="5500"
                value={job.jobid}
                onChange={(e) => setJob({ ...job, jobid: e.target.value })}
              />
            </label>
            <label htmlFor="email">
              Candidate email:
              <input
                id="email"
                placeholder="bob@example.com"
                value={job.email}
                onChange={(e) => setJob({ ...job, email: e.target.value })}
              />
            </label>
            <button
              disabled={!job.jobid || !job.email}
              onClick={() => resubmitJob()}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div className="drop-target" onDragOver={onDragOver} onDrop={onDrop}>
        Drop mails or CVs here
      </div>
    </div>
  )
}
