import {
  Msg,
  PidTagAttachFilename,
  PidTagSubject,
  PidTagSenderEmailAddress,
} from 'msg-parser'
import { getJobIdFromText } from '../dropbox/helpers'

export const getMetadata = (data: ArrayBuffer) => {
  try {
    const msg = Msg.fromUint8Array(new Uint8Array(data))
    msg.attachments().forEach((attachment) => {
      let subject = ''
      let fileNameProperty = attachment.getProperty(PidTagAttachFilename)
      if (!!fileNameProperty) {
        subject = attachment.getProperty(PidTagAttachFilename)
      } else {
        subject = 'Untitled'
      }
    })

    msg.embeddedMessages().forEach((embeddedMessage) => {
      const internalStorage = embeddedMessage.internalStorage()
      let subject = ''
      if (
        internalStorage.propertiesStream().findProperty(PidTagSubject) !==
        undefined
      ) {
        subject = internalStorage.getProperty(PidTagSubject)
      } else if (
        embeddedMessage
          .propertiesStream()
          .findProperty(PidTagAttachFilename) !== undefined
      ) {
        subject = embeddedMessage.getProperty(PidTagAttachFilename)
      } else {
        subject = 'Untitled'
      }
    })

    const subjectPropertyInfo = msg
      .propertiesStream()
      .findProperty(PidTagSubject)
    const senderPropertyInfo = msg
      .propertiesStream()
      .findProperty(PidTagSenderEmailAddress)
    const subject: string = msg.getProperty(subjectPropertyInfo.propertyTag())
    const jobId = getJobIdFromText(subject)
    const senderEmail: string = msg.getProperty(
      senderPropertyInfo.propertyTag()
    )
    return { jobId, email: senderEmail }
  } catch (e) {
    console.error(e)
    return { jobId: undefined, email: undefined }
  }
}
